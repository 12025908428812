import React, { useState } from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';
import { FormattedMessage } from 'react-intl';

import { Button, OwnerModal } from '../../../../components';
import { OWNER_USER_TYPE, RENTER_USER_TYPE } from '../../../../util/types';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';

// Section component for a website's hero section
// The Section Hero doesn't have any Blocks by default, all the configurations are made in the Section Hero settings
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    currentUser,
    description,
    appearance,
    callToAction,
    history,
    options,
  } = props;

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { userType } = currentUser?.attributes?.profile?.publicData || {}


  const routingHandler = () => {
    if (userType == RENTER_USER_TYPE) {
      setIsModalOpen(true);
    }
    else if (userType == OWNER_USER_TYPE) {
      history.push(createResourceLocatorString("NewListingPage", routeConfiguration()));
    }
    else {
      history.push(createResourceLocatorString("SignupPage", routeConfiguration()));
    }
  }

  return (
    <SectionContainer
      id={sectionId}
      className={className}
      rootClassName={classNames(rootClassName || css.root)}
      appearance={appearance}
      options={fieldOptions}
    >

      <OwnerModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      {hasHeaderFields ? (
        <header className={defaultClasses.sectionDetails}>
          <Field data={title} className={defaultClasses.title} options={fieldOptions} />
          <Field data={description} className={defaultClasses.description} options={fieldOptions} />
          {sectionId === "hero_section"
            ?
            <Button className={css.addKartButton} onClick={routingHandler}><FormattedMessage id='SectionHero.addKartText' /></Button>
            :
            null
          }
          <Field data={callToAction} className={defaultClasses.ctaButton} options={fieldOptions} />
        </header>
      ) : null}
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
