import React, { useState } from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { Button, H5, H6, Modal, NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { OWNER_USER_TYPE, RENTER_USER_TYPE } from '../../../../util/types';
import { createResourceLocatorString } from '../../../../util/routes';
import routeConfiguration from '../../../../routing/routeConfiguration';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    history,
    callToAction,
    media,
    currentUser,
    responsiveImageSizes,
    options,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);
  const newGolfCart = blockId.includes("featured_locations");
  // const newGolfCart = blockId === "section-2-block-1";
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { userType } = currentUser?.attributes?.profile?.publicData || {}

  const routingHandler = () => {
    if (userType == RENTER_USER_TYPE) {
      setIsModalOpen(true);
    }
    else if (userType == OWNER_USER_TYPE) {
      history.push(createResourceLocatorString("NewListingPage", routeConfiguration()));
    }
    else {
      history.push(createResourceLocatorString("SignupPage", routeConfiguration()));
    }
  }

  return (
    <BlockContainer id={blockId} className={classes}>
      <FieldMedia
        media={media}
        sizes={responsiveImageSizes}
        className={mediaClassName}
        options={options}
      />
      {hasTextComponentFields ? (
        <div className={classNames(textClassName, css.text)}>
          <Field data={title} options={options} />
          <Field data={text} options={options} />
          <div>
            <Button className={css.postGolfButton} type="button" onClick={routingHandler}><FormattedMessage id="BlockDefault.postNewGolf" /></Button>
          </div>
          <Field data={callToAction} className={ctaButtonClass} options={options} />
        </div>
      ) : null}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
